import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import Header from "/src/components/ui/base/layout/header"
import * as styles from "./index.module.scss"
import classnames from "classnames"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import "moment/locale/it"
import Section from "../../components/ui/base/Section"
import { createMarkup } from "../../util/tour"
import CourseCard from "../../components/ui/base/CourseCard"
import { BASE_URL, COURSES_URL } from "../../util/constants"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Faq from "../../components/ui/base/Faq"
import { FAQJsonLd } from "gatsby-plugin-next-seo"
import Banner from "../../components/ui/base/Banner"

const CoursesPage = ({ data }) => {
  const { t, i18n: l } = useTranslation()
  const courses = data.allStrapiCourse.edges
  const coursesPage = data.allStrapiCoursesPage.edges[0].node
  const pageUrl = `${BASE_URL[l.language]}/${COURSES_URL[l.language]}/`

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={coursesPage?.seo?.metaTitle}
        description={coursesPage?.seo?.metaDescription}
        canonical={pageUrl}
        openGraph={{
          url: pageUrl,
          type: "website",
          title: coursesPage?.seo?.metaTitle,
          description: coursesPage?.seo?.metaDescription,
          images: [
            {
              url: coursesPage?.seo?.shareImage.formats.medium.url,
              width: coursesPage?.seo?.shareImage.formats.medium.width,
              height: coursesPage?.seo?.shareImage.formats.medium.height,
              alt: coursesPage?.seo?.metaTitle,
            },
          ],
          site_name: "WeShoot",
        }}
      />
      <Header
        img={coursesPage?.cover?.localFile.childImageSharp.gatsbyImageData}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className={classnames([styles.header])}>
                <Typography className="animated fadeInDown" variant="heading1">
                  {coursesPage.title}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading2">
                  {coursesPage.subtitle}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Corsi di Fotografia",
                      path: `/${COURSES_URL[l.language]}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        variant={"white"}
        title={"Da zero a fotografo professionista"}
        subtitle={
          "2846 persone si sono formate con i corsi in accademia. Inizia anche tu questo bellissimo percorso"
        }
      >
        <div
          className="mb-5"
          dangerouslySetInnerHTML={createMarkup(coursesPage.body)}
        />
        {courses && courses.length > 0 && (
          <Row>
            {courses.map((course, i) => {
              return (
                <Col lg={4} md={6} className="zoomIn mb-2" key={i}>
                  <CourseCard course={course.node} />
                </Col>
              )
            })}
          </Row>
        )}
      </Section>

      {coursesPage?.faqs && coursesPage?.faqs.length > 0 && (
        <Section
          variant={"grey"}
          title={`Domande piú frequenti sui corsi di fotografia WeShoot`}
        >
          {coursesPage.faqs?.map(q => {
            return <Faq faq={q} />
          })}
          <FAQJsonLd questions={coursesPage.faqs} />
        </Section>
      )}
    </>
  )
}

export default CoursesPage

export const query = graphql`
  {
    allStrapiCourse {
      edges {
        node {
          title
          presentation
          excerpt
          slug
          price
          totalLessons
          teacher {
            profilePicture {
              localFile {
                ...ImageFragment
              }
            }
            firstName
          }
          cover {
            localFile {
              ...ImageFragment
            }
          }
          image {
            localFile {
              ...ImageFragment
            }
          }
          seo {
            shareImage {
              caption
              url
            }
            metaDescription
            metaTitle
          }
        }
      }
    }
    allStrapiCoursesPage {
      edges {
        node {
          cover {
            localFile {
              ...ImageFragment
            }
            caption
            alternativeText
          }
          title
          subtitle
          faqs {
            answer
            id
            question
          }
          seo {
            metaDescription
            metaTitle
            shareImage {
              formats {
                medium {
                  url
                  width
                  height
                }
              }
            }
          }
          body
        }
      }
    }
  }
  fragment ImageFragment on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
      thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
      full: gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`
